<template>
    <div>
        <p>
            <button type="button" class="btn btn-primary" @click="modalOpen = true">Новая категория</button>
        </p>

        <ContentTable
            class="mt-5"
            :loading="loading"
            :headers="tableHeaders"
            :table-rows="tableData"
            :table-actions="tableActions"
            :meta="pagination"
            @edit="editAction"
            @delete="deleteAction"
        />

        <ModalContainer :modal-open="modalOpen" @closeModal="closeModal">
            <template #header>
                <h2>
                    <template v-if="!updateCabinId">Создание категории каюты</template>
                    <template v-else>Редактирование категории каюты</template>
                </h2>
            </template>
            <template #body>
                <UpdateCabinType v-if="isUpdate" :cabin-type-id="updateCabinId" @dataSaved="closeModal" />
                <CreateCabinType v-else @dataSaved="closeModal" />
            </template>
        </ModalContainer>
        <PopupDeleteModal
            :is-open="modalDeleteOpen"
            :entity-name="modalDeleteCaption"
            :modal-data="modalDeleteRow"
            @update:isOpen="modalDeleteOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ModalContainer from '@/components/modals/ModalContainer';
import CreateCabinType from './CreateCabinType';
import UpdateCabinType from './UpdateCabinType';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import ContentTable from '@/components/tables/ContentTable';

export default {
    name: 'CabinType',
    components: { ContentTable, ModalContainer, CreateCabinType, UpdateCabinType, PopupDeleteModal },
    mixins: [errorResponse],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchData({ ...to.query });

        next();
    },
    data() {
        return {
            loading: false,
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'title', label: 'Заголовок (на сайте)' },
            ],
            tableActions: [
                { name: 'edit', title: 'Редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'Удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
            ],
            tableData: [],
            pagination: null,
            modalOpen: false,
            updateCabinId: null,
            isUpdate: false,
            modalDeleteOpen: false,
            modalDeleteCaption: null,
            modalDeleteRow: null,
        };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Типы кают / Редактирование');

        this.fetchData();
    },
    methods: {
        fetchData(params = { ...this.$route.query }) {
            this.loading = true;
            this.axios
                .get(`/ships/${this.$route.params.id}/cabin-types`, { params })
                .then(({ data }) => {
                    this.tableData = data.data;
                    this.pagination = data.meta;
                    this.loading = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        closeModal(updateData) {
            if (updateData) this.fetchData();
            this.updateCabinId = null;
            this.isUpdate = false;
            this.modalOpen = false;
        },
        editAction(row) {
            this.isUpdate = true;
            this.updateCabinId = row.id;
            this.modalOpen = true;
        },
        deleteAction(row) {
            this.modalDeleteRow = row;
            this.modalDeleteCaption = `категорию кают "${row.title}"`;
            this.modalDeleteOpen = true;
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete(`/ships/${this.$route.params.id}/cabin-types/${data.id}`)
                .then((res) => {
                    this.fetchData();
                    this.modalDeleteOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                    this.modalDeleteOpen = false;
                });
        },
    },
};
</script>

<style scoped></style>
