<template>
    <div>
        <h1 class="page__title">Редактирование судна</h1>

        <!--    intro-y - убрал этот класс с обертки, чтобы модалки растягивались на весь экран-->
        <div class="box p-5 mt-5">
            <div class="edit_tabs">
                <router-link v-for="(tab, idx) in tabs" :key="idx" :to="{ name: tab.link }" class="tab__item">
                    {{ tab.label }}
                </router-link>
            </div>

            <router-view class="mt-5"></router-view>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Edit',
    data() {
        return {
            tabs: [
                { label: 'Описание судна', link: 'ship-edit' },
                { label: 'Палубы', link: 'decks' },
                { label: 'Категории кают', link: 'cabin-types' },
                { label: 'Каюты', link: 'cabins' },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
.router-link-active {
    &::after {
        @apply bg-primary-1;
    }
}
.tab__item {
    position: relative;
    width: 25%;
    padding: 10px 15px;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    transition: 0.3s;

    &:hover,
    &:focus,
    &:active {
        @apply bg-blue-400 text-white;
    }

    &.router-link-active {
        @apply bg-primary-1 text-white;
    }

    &::after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
    }
}

.edit_tabs {
    display: flex;
    box-shadow: 0 4px 10px #ddd;
    border-radius: 5px;
    overflow: hidden;
}
</style>
