<template>
    <div>
        <!--    <p>-->
        <!--      <button type="button" class="btn btn-primary" @click="modalOpen = true">Создать</button>-->
        <!--    </p>-->

        <ContentTable
            class="mt-5"
            :loading="loading"
            :headers="tableHeaders"
            :table-rows="tableData"
            :table-actions="tableActions"
            :meta="pagination"
            @edit="editAction"
            @delete="deleteAction"
        />

        <ModalContainer :modal-open="modalOpen" @closeModal="closeModal">
            <template #header>
                <h2 v-if="isUpdate">Редактирование палубы</h2>
                <h2 v-else>Создать палубу</h2>
            </template>
            <template #body>
                <UpdateDeck v-if="isUpdate" :update-id="updateId" @dataSaved="closeModal" />
                <CreateDeck v-else @dataSaved="closeModal" />
            </template>
        </ModalContainer>
        <PopupDeleteModal
            :is-open="modalDeleteOpen"
            :entity-name="modalDeleteCaption"
            :modal-data="modalDeleteRow"
            @update:isOpen="modalDeleteOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ModalContainer from '@/components/modals/ModalContainer';
import CreateDeck from './CreateDeck';
import UpdateDeck from './UpdateDeck';
import ContentTable from '@/components/tables/ContentTable';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';

export default {
    name: 'Deck',
    components: {
        ContentTable,
        PopupDeleteModal,
        ModalContainer,
        CreateDeck,
        UpdateDeck,
    },
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'title', label: 'Название палубы' },
                { field: 'weight', label: 'Порядковый номер' },
            ],
            tableActions: [
                { name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                // { name: 'delete', title: 'удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
            ],
            tableData: [],
            pagination: null,
            modalOpen: false,
            isUpdate: false,
            updateId: null,
            deckData: null,
            modalDeleteOpen: false,
            modalDeleteCaption: null,
            modalDeleteRow: null,
        };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Палубы / Редактирование');

        this.fetchData();
    },
    methods: {
        fetchData() {
            this.loading = true;
            this.axios
                .get(`/ships/${this.$route.params.id}/decks`)
                .then((response) => {
                    this.tableData = response.data.data;
                    this.pagination = response.data.meta;
                    this.loading = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete(`/ships/${this.$route.params.id}/decks/${data.id}`)
                .then((res) => {
                    this.fetchData();
                    this.modalDeleteOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                    this.modalDeleteOpen = false;
                });
        },
        deleteAction(row) {
            this.modalDeleteRow = row;
            this.modalDeleteCaption = `палубу "${row.title}"`;
            this.modalDeleteOpen = true;
        },
        editAction(row) {
            this.isUpdate = true;
            this.updateId = row.id;
            this.modalOpen = true;
        },
        closeModal(updateData) {
            if (updateData) this.fetchData();
            if (this.isUpdate) this.isUpdate = false;
            this.modalOpen = false;
        },
    },
};
</script>

<style scoped></style>
