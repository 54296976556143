<template>
    <form
        method="post"
        class="grid grid-cols-12 gap-6 px-2"
        :class="{ 'disabled-form': loading }"
        @submit.prevent="saveForm"
    >
        <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
            <Preloader />
        </div>

        <div class="relative col-span-12">
            <label for="form-title" class="form-label">
                Название
                <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.title.$errors.length" for="form-title" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
            </label>

            <input
                id="form-title"
                v-model="form.title"
                type="text"
                class="form-control"
                :class="{ 'form-input-error': v$.form.title.$errors.length }"
                autocomplete="off"
            />
        </div>

        <div class="relative col-span-12">
            <label for="form-weight" class="form-label">
                Порядковый номер
                <sup v-if="v$.form.weight.required" class="text-theme-6">*</sup>
            </label>
            <label v-if="v$.form.weight.$errors.length" for="form-weight" class="form-input-text-error">
                {{ getOverrideMessage(v$.form.weight.$errors[0].$message) }}
            </label>

            <input
                id="index"
                v-model="form.weight"
                type="text"
                class="form-control"
                :class="{ 'form-input-error': v$.form.weight.$errors.length }"
            />
        </div>

        <div class="col-span-12">
            <label class="form-label">Схема</label>
            <UploadImage
                v-model="form.scheme_url"
                v-model:delete="form.scheme_url_delete"
                recommended-sizes="1170x300px"
            ></UploadImage>
        </div>

        <div class="col-span-12">
            <label class="form-label">Схема в svg</label>
            <UploadImage
                v-model="form.svg_scheme"
                v-model:delete="form.svg_scheme_delete"
                accept-extensions="image/svg+xml"
            >
                <template #text="">
                    <span class="btn btn-primary inline-block">Загрузите svg схему</span>
                </template>
            </UploadImage>
        </div>

        <div class="col-span-12 flex justify-end">
            <button type="button" class="btn btn-secondary mr-2 w-24" @click.prevent="$emit('dataSaved')">
                Отмена
            </button>
            <button type="submit" class="btn btn-primary">Сохранить</button>
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, numeric, maxValue } from '@vuelidate/validators';
import { decksMixin } from '@/mixins/form/decks-mixin';
import { errorResponse } from '@/mixins/form/form-mixin';
import UploadImage from '@/components/upload-image/UploadImage';
import Preloader from '@/components/preloader/Main';
import { helper as $h } from '@/utils/helper';
import { formPreparation } from '@/utils/object-to-form-data';

export default {
    name: 'UpdateDeck',
    components: { Preloader, UploadImage },
    mixins: [errorResponse, decksMixin],
    props: {
        updateId: {
            required: true,
            type: Number,
        },
    },
    emits: ['dataSaved'],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                title: { required },
                weight: { required, numeric, maxValue: maxValue(99) },
            },
        };
    },
    async created() {
        await this.fetchDeck();
    },
    methods: {
        prepareFormData(form) {
            const preparedFormData = JSON.parse(JSON.stringify(form));

            preparedFormData.scheme_url = typeof preparedFormData.scheme_url === 'string' ? null : form.scheme_url;
            preparedFormData.svg_scheme = typeof preparedFormData.svg_scheme === 'string' ? null : form.svg_scheme;

            return preparedFormData;
        },
        async saveForm() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            const form = formPreparation(this.prepareFormData(this.form));
            this.axios
                .post(`/ships/${this.$route.params.id}/decks/${this.updateId}`, form, {
                    params: {
                        _method: 'patch',
                    },
                })
                .then((res) => {
                    this.$emit('dataSaved', true);
                    this.$notify(res.data.message);
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped></style>
