export const decksMixin = {
    data() {
        return {
            loading: false,
            form: {
                title: null,
                weight: 0,
                scheme: null,
                scheme_url_delete: null,
                svg_scheme: null,
                svg_scheme_delete: null,
            },
        };
    },
    methods: {
        clearForm() {
            for (const key in this.form) {
                this.form[key] = null;
            }
        },
        async fetchDeck() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/ships/${this.$route.params.id}/decks/${this.updateId}/edit`);
                for (const [key, val] of Object.entries(data.data)) {
                    if (key === 'scheme') {
                        this.form['scheme_url'] = val;
                    } else {
                        this.form[key] = val;
                    }
                }

                this.loading = false;
            } catch (ex) {
                console.log('cant fetch deck: ' + ex);
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
    },
};
