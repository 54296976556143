<template>
    <div>
        <p>
            <button type="button" class="btn btn-primary" @click="modalOpen = true">Новая каюта</button>
        </p>

        <ContentTable
            class="mt-5"
            :loading="loading"
            :headers="tableHeaders"
            :table-rows="tableData"
            :table-actions="tableActions"
            :meta="pagination"
            @edit="editAction"
            @clone="cloneAction"
            @delete="deleteAction"
            @markup="markupAction"
        />

        <ModalContainer :modal-open="modalOpen" @closeModal="closeModal">
            <template #header>
                <h2>
                    <template v-if="!updateCabinId">Создать каюту</template>
                    <template v-else>Редактировать каюту</template>
                </h2>
            </template>
            <template #body>
                <UpdateCabin v-if="isUpdate" :cabin-id="updateCabinId" @dataSaved="closeModal" />
                <CreateCabin v-else :cabin-id="cloneCabinId" @dataSaved="closeModal" />
            </template>
        </ModalContainer>
        <PopupDeleteModal
            :is-open="modalDeleteOpen"
            :entity-name="modalDeleteCaption"
            :modal-data="modalDeleteRow"
            @update:isOpen="modalDeleteOpen = $event"
            @deleteModalAccepted="onDeleteModalAccepted"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ModalContainer from '@/components/modals/ModalContainer';
import CreateCabin from './CreateCabin';
import UpdateCabin from './UpdateCabin';
import PopupDeleteModal from '@/components/modals/PopupDeleteModal';
import ContentTable from '@/components/tables/ContentTable';

export default {
    name: 'Cabins',
    components: { ContentTable, ModalContainer, CreateCabin, UpdateCabin, PopupDeleteModal },
    mixins: [errorResponse],
    async beforeRouteUpdate(to, from, next) {
        await this.fetchData({ ...to.query });

        next();
    },
    data() {
        return {
            loading: false,
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'number', label: 'Номер каюты' },
                { field: 'cabinType', label: 'Тип каюты' },
                { field: 'deck', label: 'Палуба' },
                { field: 'number_seats', label: 'Мест' },
            ],
            tableActions: [
                // { name: 'clone', title: 'Клонировать', icon: 'CopyIcon', cbName: 'clone' },
                { name: 'edit', title: 'Редактировать', icon: 'Edit2Icon', cbName: 'edit' },
                { name: 'delete', title: 'Удалить', class: 'bg-red-400', icon: 'Trash2Icon', cbName: 'delete' },
            ],
            tableData: [],
            pagination: null,
            modalOpen: false,
            updateCabinId: null,
            cloneCabinId: null,
            isUpdate: false,
            modalDeleteOpen: false,
            modalDeleteCaption: null,
            modalDeleteRow: null,
        };
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        fetchData(params = { ...this.$route.query }) {
            this.loading = true;
            this.axios
                .get(`/ships/${this.$route.params.id}/cabins`, { params })
                .then(({ data }) => {
                    this.tableData = data.data;
                    this.pagination = data.meta;

                    this.loading = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                });
        },
        closeModal(updateData) {
            if (updateData) this.fetchData();
            this.isUpdate = false;
            this.updateCabinId = null;
            this.cloneCabinId = null;
            this.modalOpen = false;
        },
        editAction(row) {
            this.isUpdate = true;
            this.updateCabinId = row.id;
            this.modalOpen = true;
        },
        cloneAction(row) {
            this.isUpdate = false;
            this.cloneCabinId = row.id;
            this.modalOpen = true;
        },
        markupAction(row) {
            this.updateCabinId = row.id;
            this.modalOpen = true;
        },
        deleteAction(row) {
            this.modalDeleteRow = row;
            this.modalDeleteCaption = `каюту "${row.number}"`;
            this.modalDeleteOpen = true;
        },
        onDeleteModalAccepted(data) {
            this.axios
                .delete(`/ships/${this.$route.params.id}/cabins/${data.id}`)
                .then((res) => {
                    this.fetchData();
                    this.modalDeleteOpen = false;
                })
                .catch((ex) => {
                    this.errorResponse(ex);
                    this.modalDeleteOpen = false;
                });
        },
    },
};
</script>

<style scoped></style>
